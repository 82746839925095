<template>
  <component :is="as">
    <slot v-if="status === 'pending'" name="pending" />
    <slot v-else-if="status === 'success' && hasItems(items)" name="success" :items="items" />
    <slot v-else-if="status === 'success' && !hasItems(items)" name="empty" />
    <slot v-else name="error">
      <slot name="empty" />
    </slot>
  </component>
</template>
<script setup lang="ts" generic="T">
import type { AsTag } from "radix-vue";

import type { AsyncDataRequestStatus } from "#app";

defineSlots<{
  pending(): unknown;
  success(props: { items: T }): unknown;
  empty(): unknown;
  error(): unknown;
}>();
const { as = "section", ...props } = defineProps<{
  status: AsyncDataRequestStatus;
  items: T | undefined;
  as?: AsTag;
}>();

const items = computed<T | undefined>(() => props.items);

function hasItems(items: T | null | undefined): items is T {
  if (Array.isArray(items)) {
    return items.length > 0;
  }

  return items !== undefined && items !== null;
}
</script>
